@import 'settings.colors';
// Font
// FS Agrandir
// sass-lint:disable-all
$build: false !default;

$fonts-path: '/src/apps/webshop/callme/fonts';
$icomoon-font-path: '/src/apps/webshop/callme/fonts/icons' !default;
$images-path: '/src/apps/webshop/callme/images';
$svg-path-shared: '/src/shared/svg';

@if $build == true {
  $fonts-path: '/dist/fonts/';
}

@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Light.ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Italic.ttf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Bold.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-BoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Bold.ttf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-HeavyItalic.ttf');
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Bold.ttf');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Agrandir';
  src: url('#{$fonts-path}/PPAgrandir-Bold.ttf');
  font-style: italic;
  font-weight: 900;
}

/* Define the font family with all combinations */
@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: 'CallmeNumbers';
  src: url('#{$fonts-path}/Callme-Numbers.otf') format('opentype'),
    url('#{$fonts-path}/Callme-Numbers.ttf') format('truetype');
  font-style: italic;
  font-weight: 900;
}

// Title
$font-size-h1: 36px !default;
$font-size-h2: 24px !default;
$font-size-h3: 20px !default;
$font-size-h4: 16px !default;
$font-size-h5: 14px !default;
$font-size-h6: 12px !default;

// Other Font-Sizes
$font-size-xxs: 9px !default;
$font-size-xs: 10px;
$font-size-s: 28px;
$font-size-m: 30px;
$font-size-l: 48px;
$font-size-xl: 60px;
$font-size-xxl: 66px;

// Global settings
// Keep all 3 options to allow variables to be used in shared components
$global-font-family: 'Agrandir', Arial, Helvetica, sans-serif !default;
$global-font-family-2: $global-font-family !default;
$global-font-family-3: $global-font-family !default;
$global-font-family-4: 'CallmeNumbers', sans-serif !default;
$global-font-family-xl: $global-font-family !default;

$global-font-size: 16px !default;
$global-font-size-md: 14px !default;
$global-font-size-sm: 12px !default;
$global-font-size-xs: 10px !default;

$global-line-height-xs: 20px !default;
$global-line-height: 1.375 !default;
$global-line-height-sm: 1.5 !default;
$global-text-color: $color-black !default;
$title-line-height: 1.375 !default;

// Project prefix
$prefix: '.cm-';
// sass-lint:enable-all
