@import '/src/apps/webshop/shared/styles/sass/settings/_settings.vars.scss';

/* Updated box-shadows */
$box-shadow-default: 0px 4px 16px rgba(0, 0, 0, 0.16);
$box-shadow-hover: 0px 8px 32px rgba(0, 0, 0, 0.4);

$infoiconwidth: 18px;

/* z-index-map, use z("label") in components, ie.: z-index: z("modal"); */
$zindex: ( 'app': 0, 'skew': -1, 'slider': 10, 'tooltip': 30, 'shop-footer': 190, 'cookie': 900, 'menu': 200, 'channel': 700, 'header': 800, 'modal': 850, 'activity-indicator': 1100 );

/* Product vars */
$product-order: ( 'heading': ( 'xs': 0, 'md': 10 ), 'image': ( 'xs': 10, 'md': 0 ), 'inventory': ( 'xs': 20 ), 'description': ( 'xs': 5, 'md': 30 ), 'countdown': ( 'xs': 35 ), 'promotion': ( 'xs': 35 ), 'color': ( 'xs': 15, 'md': 40 ), 'storage': ( 'xs': 50 ), 'payment': ( 'xs': 60 ), 'insurance': ( 'xs': 62 ), 'configuration-footer':('xs': 63), 'prompt': ( 'xs': 65 ), 'subscription-offer': ( 'xs': 70 ), 'total': ( 'xs': 80 ), 'subscription': ( 'xs': 90 ), 'cta': ( 'xs': 95 ), 'usp': ( 'xs': 100 ), 'tabs': ( 'xs': 110 ) );

$product-gallery-height: 580px;
$product-image-height: 380px;
$product-image-width: 290px;
