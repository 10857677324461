/**
 * Set of commonly used text stylings.
 *
 *
 */

.text {
  &-center{
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
}

.text {
  &-bold {
    font-weight: bold;
  }

  &-bolder {
    font-weight: bolder;
  }
}