/* Colors */

/* Color: Primary */
$c-pm-900: #34091a !default;
$c-pm-850: #4e0d26 !default;
$c-pm-800: #5a1622 !default;
$c-pm-700: #832035 !default;
$c-pm-600: #e14e57 !default;
$c-pm-500: #ec5f65 !default;
$c-pm-400: #ee6f74 !default;
$c-pm-300: #f6afb2 !default;
$c-pm-200: #f9cfd1 !default;
$c-pm-100: #fdeff0 !default;

/* Color: Neutrals / Grays */
$c-nt-1000: #121214;
$c-nt-900: $c-nt-1000;
$c-nt-850: #28282a;
$c-nt-800: #303032;
$c-nt-700: #48484b;
$c-nt-600: #606064;
$c-nt-500: #8c8c90;
$c-nt-400: #b0b0b5;
$c-nt-300: #c8c8cd;
$c-nt-200: #dcdce1;
$c-nt-150: $c-nt-200;
$c-nt-100: #e8e8ed;
$c-nt-50: #f4f4f9;

/* Color: Supporting: Beige */
$c-sp-beige-900: #2c2623;
$c-sp-beige-800: #65574f;
$c-sp-beige-700: #78675d;
$c-sp-beige-600: #907d72;
$c-sp-beige-500: #a8958b;
$c-sp-beige-400: #c0b1a9;
$c-sp-beige-350: #d1c6c0;
$c-sp-beige-300: $c-sp-beige-350;
$c-sp-beige-200: #ebdfd9;
$c-sp-beige-100: #f5e9e5;
$c-sp-beige-50: #fbf7f5;

/* Color: Supporting: Green */
$c-sp-green-900: #0b231a;
$c-sp-green-800: #184d38;
$c-sp-green-700: #1c6045;
$c-sp-green-600: #27865e;
$c-sp-green-500: #33966a;
$c-sp-green-400: #55b286;
$c-sp-green-300: #87ceaa;
$c-sp-green-200: #b7e3ca;
$c-sp-green-100: #d9f2e2;

/* Color: Supporting: Teal */
$c-sp-teal-900: $c-sp-green-900;
$c-sp-teal-800: $c-sp-green-800;
$c-sp-teal-700: $c-sp-green-700;
$c-sp-teal-600: $c-sp-green-600;
$c-sp-teal-500: $c-sp-green-500;
$c-sp-teal-400: $c-sp-green-400;
$c-sp-teal-300: $c-sp-green-300;
$c-sp-teal-200: $c-sp-green-200;
$c-sp-teal-100: $c-sp-green-100;

/* Color: Supporting: Yellow */
$c-sp-yellow-900: #3e190a;
$c-sp-yellow-800: #853e1b;
$c-sp-yellow-700: #a34e1a;
$c-sp-yellow-600: #c46e1b;
$c-sp-yellow-500: #de8f22;
$c-sp-yellow-400: #e6ad39;
$c-sp-yellow-300: #ebc35f;
$c-sp-yellow-200: #f2da95;
$c-sp-yellow-100: #f9eecc;

/* Color: Supporting: Blue */
$c-sp-blue-900: #0c2b45;
$c-sp-blue-800: #0e517e;
$c-sp-blue-700: #0c5e98;
$c-sp-blue-600: #0e77bb;
$c-sp-blue-500: #1b96dc;
$c-sp-blue-400: #47b1ed;
$c-sp-blue-300: #85caf4;
$c-sp-blue-200: #bee2f9;
$c-sp-blue-100: #e2f1fc;

/* Color: Supporting: Red */
$c-sp-red-900: $c-pm-900;
$c-sp-red-700: $c-pm-700;
$c-sp-red-600: $c-pm-500;
$c-sp-red-500: $c-pm-500;
$c-sp-red-400: $c-pm-500;
$c-sp-red-600: $c-sp-red-500;
$c-sp-red-300: $c-pm-300;
$c-sp-red-200: $c-pm-200;
$c-sp-red-100: $c-pm-100;

/* Color: Supporting: Orange */
$c-sp-orange-900: #402700;
$c-sp-orange-500: #ff9b00;
$c-sp-orange-400: #ffb84d;
$c-sp-orange-300: #ffd06b;
$c-sp-orange-200: #ffdca6;
$c-sp-orange-100: #ffebcc;

/* Color: Supporting: Purple */
$c-sp-purple-500: #8c1964 !default;

/* Color: Supporting: Gold */
$c-sp-gold-500: #d39d50 !default;

/* Color: Black, white, text etc. */
$color-black: #210811 !default;
$color-border-focus: $color-black !default;
$color-display-text: #160004 !default;
$color-shadow-focus: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 8px rgba(102, 175, 233, 0.6) !default;
$color-white: #fff !default;

/* TMP: These are in "generic", should be updated/removed */
$color-gray: $c-nt-200;
$color-gray-10: $c-nt-600;

//tooltip background and border
$bordertooltipcolor: $c-nt-200;
$tooltipbck: $color-white;
$tooltiparrow: 24px;

/* payment consent colors */

$c-pc-checkbox: $c-nt-900;
$c-pc-background: #f7f5f5;
