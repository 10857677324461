// sass-lint:disable-all
// Grid

$gutter-compensation: $gutter-width * 0.5 * -1;
$half-gutter-width: $gutter-width * 0.5;
$name: xs;

#{$prefix} {
  &container {
    box-sizing: border-box;
    max-width: $flexboxgrid-max-width;
    margin-right: auto;
    margin-left: auto;
    padding-right: $outer-margin;
    padding-left: $outer-margin;
    width: 100%;

    &--md {
      max-width: 780px;
    }

    &--lg {
      max-width: 1200px;
    }

    &--xl {
      max-width: 1920px;
    }

    &--unlimited {
      max-width: none;
    }
  }

  &grid {
    box-sizing: border-box;
    margin-right: $gutter-compensation;
    margin-left: $gutter-compensation;
    @include flexbox();
    @include flex(0, 1, auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);
  }

  &grid--inline {
    @include inline-flex;

    #{$prefix}grid__col--xl,
    #{$prefix}grid__col--lg,
    #{$prefix}grid__col--md,
    #{$prefix}grid__col--sm,
    #{$prefix}grid__col--xs {
      @include flex-basis(auto);
    }
  }

  &grid--reverse {
    @include flex-direction(row-reverse);
  }

  &grid__col--reverse {
    @include flex-direction(column-reverse);
  }

  @mixin flexboxgrid-sass-col-common {
    box-sizing: border-box;
    // we leave @include flex-basis(auto) out of common because
    // in some spots we need it and some we dont
    // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126
    padding-right: $half-gutter-width;
    padding-left: $half-gutter-width;
    // split @include flex(0, 0, auto) into individual props
    @include flex-grow(0);
    @include flex-shrink(0);
  }

  &grid__col--#{$name} {
    @include flexboxgrid-sass-col-common;
    @include flex-basis(auto);
  }

  @for $i from 1 through $grid-columns {
    &grid__col--#{$name}-#{$i} {
      max-width: 100% / $grid-columns * $i;
      @include flexboxgrid-sass-col-common;
      @include flex-basis(100% / $grid-columns * $i);
    }
  }

  @for $i from 0 through $grid-columns {
    &grid__col--#{$name}-offset-#{$i} {
      @include flexboxgrid-sass-col-common;

      @if $i == 0 {
        margin-left: 0;
      } @else {
        margin-left: 100% / $grid-columns * $i;
      }
    }
  }

  &grid__col--#{$name} {
    max-width: 100%;
    @include flex-grow(1);
    @include flex-basis(0);
  }

  &grid--start-#{$name} {
    text-align: start;
    @include justify-content(flex-start);
  }

  &grid--center-#{$name} {
    text-align: center;
    @include justify-content(center);
  }

  &grid--end-#{$name} {
    text-align: end;
    @include justify-content(flex-end);
  }

  &grid--top-#{$name} {
    @include align-items(flex-start);
  }

  &grid--middle-#{$name} {
    @include align-items(center);
  }

  &grid--bottom-#{$name} {
    @include align-items(flex-end);
  }

  &grid--around-#{$name} {
    @include justify-content(space-around);
  }

  &grid--between-#{$name} {
    @include justify-content(space-between);
  }

  &grid_col--first-#{$name} {
    order: -1;
  }

  &grid_col--last-#{$name} {
    order: 1;
  }

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);
    $container: nth($breakpoint, 3);

    @media only screen and (min-width: $size) {
      &grid__col--#{$name} {
        @include flexboxgrid-sass-col-common;
        @include flex-basis(auto);
      }

      @for $i from 1 through $grid-columns {
        &grid__col--#{$name}-#{$i} {
          max-width: 100% / $grid-columns * $i;
          @include flexboxgrid-sass-col-common;
          @include flex-basis(100% / $grid-columns * $i);
        }
      }

      @for $i from 0 through $grid-columns {
        &grid__col--#{$name}-offset-#{$i} {
          @include flexboxgrid-sass-col-common;

          @if $i == 0 {
            margin-left: 0;
          } @else {
            margin-left: 100% / $grid-columns * $i;
          }
        }
      }

      &grid__col--#{$name} {
        max-width: 100%;
        @include flex-grow(1);
        @include flex-basis(0);
      }

      &grid--start-#{$name} {
        text-align: start;
        @include justify-content(flex-start);
      }

      &grid--center-#{$name} {
        text-align: center;
        @include justify-content(center);
      }

      &grid--consent-#{$name} {
        text-align: center;
      }

      &grid--end-#{$name} {
        text-align: end;
        @include justify-content(flex-end);
      }

      &grid--top-#{$name} {
        @include align-items(flex-start);
      }

      &grid--middle-#{$name} {
        @include align-items(center);
      }

      &grid--bottom-#{$name} {
        @include align-items(flex-end);
      }

      &grid--around-#{$name} {
        @include justify-content(space-around);
      }

      &grid--between-#{$name} {
        @include justify-content(space-between);
      }

      &grid__col--first-#{$name} {
        order: -1;
      }

      &grid_col--last-#{$name} {
        order: 1;
      }
    }
  }
}
// sass-lint:enable-all
//.cm-grid__col--xl-2 { max-width: 20%; flex-basis: 20%; }
