@import url('./oneblock-widgets.css');
/**
 * oneblock-config.css
 * @version 1.1.41
 * @summary 23-09-2020
 * @author Mads Stoumann
 * @description OneBlock: Default Configuration.
 * NOTE: This file should be changed/updated for every client
 */

/*
Naming: `--ob` + ELEMENT-PREFIX + EMMET-abbr + (Optional) NUMERIC VALUE
Default values use NUMERIC VALUE 0 (Zero)

ELEMENT PREFIX
-A Additional headline
-B Background Image/Video
-C Image Caption
-E Content Box
-H Headline
-I Image
-K Secondary Link
-L Primary Link
-M Meta
-N Tagline
-O OneBlock
-P Offer/Price
-S Splash
-Dh Display headline
-T Text
-W Media Wrapper
-Cnt Countdown
*/

/* CSS Custom Properties */
:root {
  /* GENERIC */
  --ob-alpha: 1;
  --ob-bdrs: 0;
  --ob-color-transparent: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  --ob-distance-lr: 16px;
  --ob-distance-tb: 16px;
  --ob-distance-unit: 16px;
  --ob-font-1: Agrandir, Arial, sans-serif;
  /* Body Text Font */
  --ob-font-2: Agrandir, Arial, sans-serif;
  --ob-font-3: Agrandir, Arial, sans-serif;
  --ob-font-4: CallmeNumbers, Arial, sans-serif;
  /* Headline Font */
  --ob-grid-gap: 16px;
  --ob-margin: 16px;
  --ob-margin-unit: 16px;
  --ob-translate-x: 0px;
  --ob-translate-y: 0px;
  --ob-zindex: 3;

  /*
	========
	ONEBLOCK
	========
	*/
  --ob-O-bg1: #ffffff;
  --ob-O-bg2: #EC5F65;
  --ob-O-bg3: #4E0D26;
  --ob-O-bg4: #F4F4F9;
  --ob-O-bg5: #FBF7F5;
  --ob-O-br1: 2px;
  --ob-O-br2: 3px;
  --ob-O-br3: 5px;
  --ob-O-br4: 1em;
  --ob-O-bx1: 0px 10px 8px -8px rgba(0, 0, 0, 0.08);
  --ob-O-bx2: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  --ob-O-bx3: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  --ob-O-bx4: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0, 0, 0, 0.05);
  --ob-O-bx5: 0 12px 32px rgba(0, 0, 0, 0.16);
  --ob-O-fz0: 1rem;
  --ob-O-bx-hv2: var(--ob-O-bx5);

  /*
	===========
	CONTENT BOX
	===========
	Background Color in raw RGB. Alpha (--ob-alpha) is added/combined in oneblock-color.css
	*/
  --ob-E-bg1: 255, 255, 255;
  --ob-E-bg2: 0, 0, 0;
  --ob-E-bg3: 235, 0, 0;
  --ob-E-c1: #fff;
  --ob-E-c2: #EC5F65;
  --ob-E-c3: #210811;
  --ob-E-c4: #646464;
  --ob-E-c5: #4E0D26;
  /* Padding Units */
  --ob-E-pt: 0.5rem;
  --ob-E-pr: 0.5rem;
  --ob-E-pb: 0.5rem;
  --ob-E-pl: 0.5rem;

  /* Defaults */
  --ob-E-pt0: 1.5rem;
  --ob-E-pr0: 1rem;
  --ob-E-pb0: 1.5rem;
  --ob-E-pl0: 1rem;
  /*
	========
	HEADLINE
	========
	*/
  --ob-H-c0: #210811;
  --ob-H-c1: #fff;
  --ob-H-c2: #EC5F65;
  --ob-H-c3: #210811;
  --ob-H-c4: #4E0D26;

  --ob-H-fz14: 14px;
  --ob-H-lh14: 20px;
  --ob-H-fz1: 18px;
  --ob-H-lh1: 24px;
  --ob-H-fz20: 20px;
  --ob-H-lh20: 24px;
  --ob-H-fz2: 22px;
  --ob-H-lh2: 24px;
  --ob-H-fz3: 24px;
  --ob-H-lh3: 28px;
  --ob-H-fz4: 30px;
  --ob-H-lh4: 32px;
  --ob-H-fz5: 32px;
  --ob-H-lh5: 36px;
  --ob-H-fz6: 36px;
  --ob-H-lh6: 40px;
  --ob-H-fz7: 40px;
  --ob-H-lh7: 44px;
  --ob-H-fz8: 48px;
  --ob-H-lh8: 56px;
  --ob-H-fz9: 66px;
  --ob-H-lh9: 72px;
  --ob-H-fz10: calc(18px + (60 - 18) * ((100vw - 300px) / (3200 - 300)));
  --ob-H-lh10: 1.3;
  --ob-H-fw1: 900;
  --ob-H-fw2: 900;
  --ob-H-fw3: 900;
  --ob-H-fw4: 900;
  --ob-H-fw5: 900;

  /* Defaults */
  --ob-H-ff0: var(--ob-font-2);
  --ob-H-fz0: var(--ob-H-fz4);
  --ob-H-lh0: var(--ob-H-lh4);
  --ob-H-mb0: var(--ob-margin-unit);
  --ob-H-fw0: 900;
  --ob-H-tt0: none;
  /*
	========
	ADDITIONAL HEADLINE
	========
	*/
  --ob-A-c0: #210811;
  --ob-A-c1: #fff;
  --ob-A-c2: #EC5F65;
  --ob-A-c3: #4E0D26;

  --ob-A-fz1: 10px;
  --ob-A-lh1: 14px;
  --ob-A-fz2: 12px;
  --ob-A-lh2: 16px;
  --ob-A-fz3: 14px;
  --ob-A-lh3: 20px;
  --ob-A-fz4: 16px;
  --ob-A-lh4: 24px;
  --ob-A-fz5: 20px;
  --ob-A-lh5: 28px;
  --ob-A-fz6: 24px;
  --ob-A-lh6: 28px;

  --ob-A-fz10: 10px;
  --ob-A-lh10: 14px;
  --ob-A-fz12: 12px;
  --ob-A-lh12: 16px;
  --ob-A-fz14: 14px;
  --ob-A-lh14: 20px;
  --ob-A-fz16: 16px;
  --ob-A-lh16: 24px;
  --ob-A-fz20: 20px;
  --ob-A-lh20: 28px;
  --ob-A-fz24: 24px;
  --ob-A-lh24: 28px;
  /* --ob-A-fz10: calc(18px + (60 - 18) * ((100vw - 300px) / (3200 - 300)));
  --ob-A-lh10: 1.3; */

  --ob-A-fw1: 400;
  --ob-A-fw2: 500;
  --ob-A-fw3: 700;
  --ob-A-fw4: 800;
  --ob-A-fw5: 900;

  /* Defaults */
  --ob-A-ff0: var(--ob-font-2);
  --ob-A-fz0: var(--ob-A-fz14);
  --ob-A-lh0: var(--ob-A-lh14);
  --ob-A-mb0: var(--ob-margin-unit);
  --ob-A-fw0: var(--ob-A-fw1);
  --ob-A-tt0: none;
  /*
	========
	DISPLAY HEADLINE
	========
	*/
  --ob-Dh-cl0: #210811;
  --ob-Dh-cl1: #fff;
  --ob-Dh-cl2: #EC5F65;

  --ob-Dh-fz1: 14px;
  --ob-Dh-lh1: 14px;
  --ob-Dh-fz2: 18px;
  --ob-Dh-lh2: 18px;
  --ob-Dh-fz3: 24px;
  --ob-Dh-lh3: 24px;
  --ob-Dh-fz4: 32px;
  --ob-Dh-lh4: 32px;
  --ob-Dh-fz5: 40px;
  --ob-Dh-lh5: 40px;
  --ob-Dh-fz6: 48px;
  --ob-Dh-lh6: 48px;
  --ob-Dh-fz7: 66px;
  --ob-Dh-lh7: 66px;


  /* Defaults */
  --ob-Dh-ff0: var(--ob-font-2);
  --ob-Dh-fz0: var(--ob-Dh-fz14);
  --ob-Dh-lh0: var(--ob-Dh-lh14);
  --ob-Dh-mb0: var(--ob-margin-unit);
  --ob-Dh-fw0: 900;
  --ob-Dh-tt0: none;

  /*
	=============
	IMAGE CAPTION
	=============
	*/
  --ob-C-bg0: transparent;
  --ob-C-bg1: #210811;
  --ob-C-bg2: #EC5F65;
  --ob-C-bg3: #ffffff;
  --ob-C-c1: #ffffff;
  --ob-C-c2: #210811;

  --ob-C-fz1: 12px;
  --ob-C-lh1: 20px;
  --ob-C-fz2: 18px;
  --ob-C-lh2: 24px;
  --ob-C-fz3: 22px;
  --ob-C-lh3: 28px;
  --ob-C-fz4: 24px;
  --ob-C-lh4: 28px;
  --ob-C-fz5: 32px;
  --ob-C-lh5: 38px;
  --ob-C-fz6: 36px;
  --ob-C-lh6: 44px;
  --ob-C-fz7: 48px;
  --ob-C-lh7: 56px;
  --ob-C-fz8: 60px;
  --ob-C-lh8: 70px;
  --ob-C-fz9: 72px;
  --ob-C-lh9: 1em;
  --ob-C-fz10: calc(18px + (60 - 18) * ((100vw - 300px) / (3200 - 300)));
  --ob-C-lh10: 1.3;
  /* Padding Units */
  --ob-C-pl: 0.125rem;
  --ob-C-pt: 0.125rem;

  --ob-C-fw1: 300;
  --ob-C-fw2: 400;
  --ob-C-fw3: 500;
  --ob-C-fw4: 700;
  --ob-C-fw5: 900;

  /* Defaults */
  --ob-C-ff0: var(--ob-font-1);
  --ob-C-fz0: 14px;
  --ob-C-lh0: var(--ob-H-lh1);
  --ob-C-fw0: 500;
  --ob-C-pl0: 0.5rem;
  --ob-C-pt0: 0.125rem;

  /*
    ======
    RIBBON
    ======
    */
  --ob-R-bg0: #4E0D26;
  --ob-R-bg1: #4E0D26;
  --ob-R-bg2: #EC5F65;
  --ob-R-bg3: #ffffff;
  --ob-R-c0: #ffffff;
  --ob-R-c1: #ffffff;
  --ob-R-c2: #4E0D26;
  --ob-R-fz0: 14px;
  --ob-R-fw0: 500;
  --ob-R-m0: 0;
  --ob-R-p0: 0.125rem 0.5rem;
  --ob-R-fs0: none;
  --ob-R-br0: 0;
  --ob-R-br1: 2px 0 0 0;
  --ob-R-br2: 3px 0 0 0;

  /*
	=====
	LINKS
	=====
	*/
  --ob-L-bdrs: 0;
  --ob-L-bdw: 1px;
  --ob-L-cut: 7px;
  --ob-L-bgc1: #4E0D26;
  --ob-L-bgc2: #ffffff;
  --ob-L-bgc3: transparent;
  --ob-L-c1: #ffffff;
  --ob-L-c2: #4E0D26;
  --ob-L-bgh1: #832035;
  --ob-L-bgh2: #ffe5e6;
  --ob-L-bgh3: #4E0D26;
  --ob-L-bgh4: #ffffff;

  --ob-L-ff0: inherit;
  --ob-L-fw0: 800;
  --ob-L-fw0: 500;
  --ob-L-fz0: 14px;
  --ob-L-fz1: 16px;
  --ob-L-lh0: inherit;
  --ob-L-p: 0.65rem 1.4rem;
  --ob-L-bdrs: 24px;
  --ob-L-bdrs1: 16px;


  /*
	====
	META
	====
	*/
  --ob-M-c1: #210811;
  --ob-M-c2: #333;
  --ob-M-c3: #666;
  --ob-M-c4: #999;
  --ob-M-sz: 1rem;

  /* Defaults */
  --ob-M-ff0: var(--ob-font-1);
  --ob-M-fz0: var(--ob-T-fz1);
  --ob-M-lh0: var(--ob-T-lh1);
  --ob-M-fw0: 400;
  --ob-M-mb0: var(--ob-margin-unit);
  --ob-M-icon-mie: 0.15em;
  --ob-M-item-mie: 0.65em;
  --ob-M-tt0: normal;

  /*
	=============
	PRICE / OFFER
	=============
	*/
  --ob-P-bf1: 12px;
  --ob-P-bf2: 16px;
  --ob-P-bf3: 24px;
  --ob-P-bf4: 32px;
  --ob-P-pf1: 22px;
  --ob-P-pf2: 32px;
  --ob-P-pf3: 36px;
  --ob-P-pf4: 48px;
  --ob-P-cf1: 14px;
  --ob-P-cf2: 16px;
  --ob-P-tf1: 10px;
  --ob-P-tf2: 12px;
  --ob-P-lf1: 12px;
  --ob-P-lf2: 14px;
  --ob-P-lf3: 16px;

  /* Defaults */
  --ob-P-base-c0: var(--ob-O-bg3);
  --ob-P-base-ff0: var(--ob-font-2);
  --ob-P-base-fz0: 16px;
  --ob-P-base-fw0: 900;
  --ob-P-base-td0: line-through;
  --ob-P-curr-fz0: 14px;
  --ob-P-price-c0: var(--ob-O-bg3);
  --ob-P-price-ff0: var(--ob-font-2);
  --ob-P-price-ff1: var(--ob-font-4);
  --ob-P-price-fz0: 36px;
  --ob-P-price-fw0: 900;
  --ob-P-text-c0: #6e6e6e;
  --ob-P-text-ff0: var(--ob-font-1);
  --ob-P-text-fz0: 12px;
  --ob-P-text-fw0: inherit;
  --ob-P-label-ff0: var(--ob-font-1);
  --ob-P-label-fz0: 14px;
  --ob-P-label-fw0: inherit;
  --ob-P-lh0: 1;
  --ob-P-m0: 0 0 var(--ob-margin-unit) 0;

  /*
	======
	SPLASH
	======
	*/
  --ob-S-bg0: #EC5F65;
  --ob-S-bg1: #ffffff;
  --ob-S-bg2: #210811;
  --ob-S-bg3: none;

  --ob-S-c0: #210811;
  --ob-S-c1: #EC5F65;
  --ob-S-c2: #ffffff;

  --ob-S-fz1: 12px;
  --ob-S-fz2: 14px;
  --ob-S-fz3: 18px;
  --ob-S-fz4: 24px;
  --ob-S-fz5: 36px;

  --ob-S1-fw1: 300;
  --ob-S1-fw2: 400;
  --ob-S1-fw3: 500;
  --ob-S1-fw4: 700;
  --ob-S1-fw5: 900;

  --ob-S2-fw1: 300;
  --ob-S2-fw2: 400;
  --ob-S2-fw3: 500;
  --ob-S2-fw4: 700;
  --ob-S2-fw5: 900;

  --ob-S3-fw1: 300;
  --ob-S3-fw2: 400;
  --ob-S3-fw3: 500;
  --ob-S3-fw4: 700;
  --ob-S3-fw5: 900;

  --ob-S-sz1: 64px;
  --ob-S-sz2: 80px;
  --ob-S-sz3: 96px;
  --ob-S-sz4: 128px;
  --ob-S-sz5: 176px;

  --ob-S-ol1: #ffffff;
  --ob-S-ol2: #EC5F65;
  --ob-S-ol3: #210811;

  /* Defaults */
  /* --ob-S-c0: #ffffff; */
  --ob-S-sz0: var(--ob-S-sz2);
  --ob-S1-ff0: var(--ob-font-1);
  --ob-S2-ff0: var(--ob-font-2);
  --ob-S3-ff0: var(--ob-font-1);
  --ob-S4-ff0: var(--ob-font-4);
  --ob-S1-fw0: var(--ob-S1-fw4);
  --ob-S2-fw0: var(--ob-S1-fw4);
  --ob-S3-fw0: var(--ob-S1-fw4);
  --ob-S1-fz0: var(--ob-S-fz1);
  --ob-S2-fz0: var(--ob-S-fz1);
  --ob-S3-fz0: var(--ob-S-fz1);

  /*
	=======
	TAGLINE
	=======
	*/
  --ob-N-c1: #210811;
  --ob-N-c2: #fff;
  --ob-N-c3: #EC5F65;

  /* Defaults */
  --ob-N-ff0: var(--ob-font-1);
  --ob-N-fz0: var(--ob-T-fz1);
  --ob-N-lh0: var(--ob-T-fz3);
  --ob-N-lts0: 1px;
  --ob-N-mb0: var(--ob-margin-unit);
  --ob-N-fw0: 400;
  --ob-N-tt0: uppercase;

  /*
	====
	TEXT
	====
	*/
  --ob-T-fz7: 10px;
  --ob-T-lh7: 12px;
  --ob-T-fz1: 12px;
  --ob-T-lh1: 16px;
  --ob-T-fz2: 14px;
  --ob-T-lh2: 20px;
  --ob-T-fz3: 16px;
  --ob-T-lh3: 24px;
  --ob-T-fz4: 18px;
  --ob-T-lh4: 24px;
  --ob-T-fz5: 20px;
  --ob-T-lh5: 24px;
  --ob-T-fz6: 24px;
  --ob-T-lh6: 28px;
  --ob-T-fz10: calc(16px + (24 - 16) * ((100vw - 300px) / (3200 - 300)));
  --ob-T-lh10: 1.6;

  /*
	====
	COUNTDOWN
	====
	*/
  --ob-Cnt-digit-fz1: 66px;
  --ob-Cnt-digit-fz2: 48px;
  --ob-Cnt-digit-fz3: 40px;
  --ob-Cnt-digit-fz4: 24px;
  --ob-Cnt-digit-fz5: 18px;

  --ob-Cnt-digit-width-sz1: 95px;
  --ob-Cnt-digit-width-sz2: 69px;
  --ob-Cnt-digit-width-sz3: 58px;
  --ob-Cnt-digit-width-sz4: 35px;
  --ob-Cnt-digit-width-sz5: 26px;

  --ob-Cnt-label-fz1: 14px;
  --ob-Cnt-label-fz2: 14px;
  --ob-Cnt-label-fz3: 12px;
  --ob-Cnt-label-fz4: 12px;
  --ob-Cnt-label-fz5: 10px;

  --ob-Cnt-headline-fz1: 16px;
  --ob-Cnt-headline-fz2: 16px;
  --ob-Cnt-headline-fz3: 16px;
  --ob-Cnt-headline-fz4: 14px;
  --ob-Cnt-headline-fz5: 12px;

  /* Defaults */
  --ob-T-ff0: var(--ob-font-1);
  --ob-T-fz0: var(--ob-T-fz3);
  --ob-T-lh0: var(--ob-T-lh3);
  --ob-T-mb0: var(--ob-margin-unit);
  --ob-T-fw0: 400;
}

/*
====================
CUSTOM CLIENT STYLES
====================
*/

/* Image Caption: Text-Mask */
.-C-tm1 .c-ob__bg-caption {
  bottom: 0;
  height: 100%;
  max-height: calc(100% - calc(var(--ob-distance-tb) * 2));
  max-width: calc(100% - calc(var(--ob-distance-lr) * 2));
  mix-blend-mode: screen;
  overflow: hidden;
  width: 100%;
}

/* Headline && Additional headline: Qoute Styles */
.-A-qs1 .c-ob__additional-headline,
.-H-qs1 .c-ob__headline {
  border-left: 0.25em solid hsl(200, 40%, 80%);
  padding-left: 1.75em;
}

.-A-qs1 .c-ob__additional-headline::before,
.-H-qs1 .c-ob__headline::before {
  color: hsl(200, 40%, 80%);
  content: '\201C';
  font-family: inherit;
  font-size: 4em;
  left: 0.1em;
  position: absolute;
  top: 0.1em;
}

/* Link: Style: BTN */
.-L-st1 .c-ob__link-text,
.-L-st2 .c-ob__link-text,
.-K-st1 .c-ob__link-text-sec,
.-K-st2 .c-ob__link-text-sec {
  font-family: var(--ob-font-1);
  border-color: transparent;
  border-radius: var(--ob-L-bdrs);
  border-style: solid;
  cursor: pointer;
  border-width: var(--ob-L-bdw);
  box-sizing: border-box;
  padding: var(--ob-L-p);
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  z-index: 1;
  font-weight: var(--ob-L-fw0);
  font-style: none;
}

/* Link: Style: BTN-fill */
.-L-st1.-L-bg1 .c-ob__link-text,
.-K-st1.-K-bg1 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc1);
  color: var(--ob-L-c1);
}

.-L-st1.-L-bg2 .c-ob__link-text,
.-K-st1.-K-bg2 .c-ob__link-text-sec {
  background-color: var(--ob-L-bgc2);
  color: var(--ob-L-c2);
}

.-L-st1.-L-bg1 .c-ob__link-text:hover,
.-K-st1.-K-bg1 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh1);
  color: var(--ob-L-c1);
}
.-L-st1.-L-bg2 .c-ob__link-text:hover,
.-K-st1.-K-bg2 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh2);
  color: var(--ob-L-c2);
}

/* Link: Style: BTN-outline */
.-L-st2.-L-bg1 .c-ob__link-text,
.-K-st2.-K-bg1 .c-ob__link-text-sec {
  border-color: var(--ob-L-bgc1);
  background-color: var(--ob-L-bgc3);
  color: var(--ob-L-c2);
}

.-L-st2.-L-bg2 .c-ob__link-text,
.-K-st2.-K-bg2 .c-ob__link-text-sec {
  border-color: var(--ob-L-bgc2);
  background-color: var(--ob-L-bgc3);
  color: var(--ob-L-c1);
}

.-L-st2.-L-bg1 .c-ob__link-text:hover,
.-K-st2.-K-bg1 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh3);
  color: var(--ob-L-c1);
}
.-L-st2.-L-bg2 .c-ob__link-text:hover,
.-K-st2.-K-bg2 .c-ob__link-text-sec:hover {
  background-color: var(--ob-L-bgh4);
  color: var(--ob-L-c2);
}

/* Link BTN Sizes */
.-L-st1.-L-sz1 .c-ob__link-text,
.-L-st2.-L-sz1 .c-ob__link-text,
.-K-st1.-K-sz1 .c-ob__link-text-sec,
.-K-st2.-K-sz1 .c-ob__link-text-sec {
  border-radius: var(--ob-L-bdrs1);
  font-size: var(--ob-L-fz0);
  padding: 0.375em 1.1em;
}

.-L-st1.-L-sz2 .c-ob__link-text,
.-L-st2.-L-sz2 .c-ob__link-text,
.-K-st1.-K-sz2 .c-ob__link-text-sec,
.-K-st2.-K-sz2 .c-ob__link-text-sec {
  font-size: var(--ob-L-fz1);
  padding: 0.7em 1.35em;
}

@media only screen and (max-width: 600px) {
  .c-ob__link-text,
  .c-ob__link-text-sec {
    /*
        HACK: FORCE DEFAULT BUTTONS TO SMALL, WHEN ON MOBILE
        !important is used in order to target all scenarios.
        */
    font-size: 14px !important;
    padding: 0.375em 1.1em !important;
  }
}
/* Image Caption Styles */
.c-ob__bg-caption {
  font-style: none;
  border-radius: var(--ob-bdrs) 0 0 0;
}

/* Tagline: Styles */
.-N-st1 .c-ob__tagline {
  color: var(--ob-N-c1);
}

.-N-st2 .c-ob__tagline {
  color: var(--ob-N-c2);
}

.-N-st3 .c-ob__tagline {
  color: var(--ob-N-c3);
}

/* Video Poster Play Overlay */
.c-ob__bg-play svg {
  cursor: pointer;
  display: inline-block;
  fill: var(--ob-O-bg-1);
  height: 30%;
  left: 50%;
  max-height: 7rem;
  max-width: 7rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
}

.c-ob__bg-play:hover svg {
  fill: #fff;
}

/* Custom Shapes */
.-B-sh10 .c-ob__bg {
  clip-path: url('#svg-curve-telia');
}

.-B-sh11 .c-ob__bg {
  clip-path: url('#svg-pebble-telia');
}

/* Counter in OneBlock */
.c-ob__countdown .c-cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1;
  margin-bottom: var(--ob-margin-unit);
}

.c-ob__countdown .c-cnt__headline {
  display: block;
  flex: 0 0 100%;
  font-family: var(--ob-font-2);
  font-size: var(--ob-Cnt-headline-fz3);
  line-height: 1.6;
  font-family: var(--ob-font-3);
}

.c-ob__countdown .c-cnt__item,
.c-ob__countdown .c-cnt__divider {
  font-family: var(--ob-font-2);
  font-size: var(--ob-Cnt-digit-fz3);
  line-height: 0.95;
  text-align: center;
  font-weight: 900;
}
.c-ob__countdown .c-cnt__item {
  display: inline-flex;
  flex-direction: column;
}

.c-ob__countdown .c-cnt__item:last-of-type {
  text-align: left;
  width: var(--ob-Cnt-digit-width-sz3);
}

.c-ob__countdown .c-cnt__divider {
  margin: 0 4px;
}

.c-ob__countdown .c-cnt__label {
  font-family: var(--ob-font-1);
  font-weight: 700;
  font-size: var(--ob-Cnt-label-fz3);
  text-align: center;
  text-transform: capitalize;
  font-family: var(--ob-font-3);
}

@media all and (-ms-high-contrast: none) {
  /* Fixes for IE 11 HERE */
}

/* Display headline font */
.c-ob__display-headline .shouting-font {
  display: inline-block;
  margin-right: 10px;
  font-style: italic;
  font-style: none;
}

div[style*="z-index: 3000"] {
  display: none;
}