/* TODO: TEMP CMS Consolidation project colors, should be aligned with the rest of the webshop */
$colors: (
  primary: (
    core: (
      900: $c-pm-900,
      850: $c-pm-850,
      800: $c-pm-800,
      700: $c-pm-700,
      600: $c-pm-600,
      500: $c-pm-500,
      400: $c-pm-400,
      300: $c-pm-300,
      200: $c-pm-200,
      100: $c-pm-100,
    ),
    light: (
      100: #ffffff,
      90: #fbfbfb
    ),
    dim: (
      100: $c-nt-50
    ),
    dark: (
      100: $color-black,
      90: #7e6c6c,
      80: #75757a,
      70: #444,
      60: #d3d3d5
    )
  ),
  backgrounds: (
    bg1: (
      100: $color-white
    ),
    bg2: (
      100: $c-nt-50
    ),
    bg3: (
      100: $c-sp-beige-50
    ),
    bg4: (
      100: $c-pm-500
    ),
    bg5: (
      100: $c-pm-850
    ),
    bg6: (
      100: transparent
    )
  ),
  accent: (
    /* TODO: regroup/split this section based on their function */ blue:
      (
        100: #0a0055,
        90: #484080,
        80: #847faa,
        70: #c2bfd4
      ),
    curry: (
      100: #d39d50,
      90: #deb67c,
      80: #eedabd,
      70: #f4e6d3
    ),
    turquoise: (
      100: #22d3c5,
      90: #5aded4,
      80: #90e9e2,
      70: #c8f4f0
    ),
    ocean: (
      100: #e3fff1,
      200: #d9ffec,
      300: #c3f9de,
      400: #a3ffd1,
      500: #0dffc5,
      600: #00dba8,
      700: #00a881,
      800: #00755a,
      900: #004233
    ),
    raspberry: (
      100: #8c1964,
      90: #a9538b,
      80: #c58cb1,
      70: #e2c5d8
    ),
    grey: (
      100: #a8a8ac,
      90: #bebec1,
      80: #d3d3d5,
      70: #e9e9ea,
      50: #d8d8d8
    )
  )
);

// color(primary, core)
@function color($section, $name, $tint: 100) {
  @return map-get(map-get(map-get($colors, $section), $name), $tint);
}
