// Helpers
#{$prefix} {
  // .cm-sr-only
  &sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
  }
  // .cm-is-hidden
  &is-hidden {
    visibility: hidden;
  }
  // .cm-text-left
  &text-left {
    text-align: left;
  }

  &text-left-md {
    @include mq($screen-sm, min) {
      text-align: left;
    }
  }

  // .cm-text-center
  &text-center {
    text-align: center;
  }

  &text-center-md {
    @include mq($screen-sm, min) {
      text-align: center;
    }
  }

  &text-center-sm {
    @include mq($screen-sm, max) {
      text-align: center;
    }
  }
  // .cm-text-right
  &text-right {
    text-align: right;
  }

  &text-right-md {
    @include mq($screen-sm, min) {
      text-align: right;
    }
  }
  // .cm-text-bold
  &text-bold {
    font-weight: 700;
  }
  // .cm-text-disabled
  &text-disabled {
    color: $c-nt-200;
  }
  // .cm-text-brand-red
  &text-brand-red {
    color: $c-pm-500;
  }
  // .cm-text-light
  &text-light {
    color: $c-nt-600;
  }
  // .cm-text-sm
  &text-sm {
    font-size: $font-size-h6;
  }
  // Margins
  // .cm-mb
  &mb {
    margin-bottom: $gutter-width;

    // .cm-mb--sm
    &--sm {
      margin-bottom: 30px;
    }

    &--md {
      margin-bottom: $gutter-width * 2;
    }
    // .cm-mb--lg
    &--lg {
      margin-bottom: 75px;
    }
    &-top {
      &--ssm {
        margin-top: $u-200;
      }
      &--sm {
        margin-top: 30px;
      }
      &--md {
        margin-top: $gutter-width * 2;
      }
    }
  }
}

.-white {
  color: $color-white;
}

-black {
  color: $color-black;
}

[v-cloak],
.hidden {
  display: none;
}

.norlys-only {
  display: none;
}

.field-validation-error {
  color: $c-pm-500;
}
